import axios from "axios";
import React, { createContext, useContext, useState } from "react";

const PartnerContext = createContext();

export const PartnerProvider = ({ children }) => {
  const [partners, setPartners] = useState([]);

  // Fetch partners
  const fetchPartners = async () => {
    try {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      if (!baseURL) {
        throw new Error("Base URL is missing in environment variables.");
      }
      const response = await axios.get(`${baseURL}/partners`);
      setPartners(response.data.data);
    } catch (error) {
      console.error("Error fetching partners:", error);
      throw error; // Propager l'erreur à l'appelant
    }
  };

  // Context value
  const value = {
    partners,
    fetchPartners,
  };

  return (
    <PartnerContext.Provider value={value}>{children}</PartnerContext.Provider>
  );
};

// Custom hook to use PartnerContext
export const usePartnerContext = () => {
  const context = useContext(PartnerContext);
  if (!context) {
    throw new Error("usePartnerContext must be used within a PartnerProvider");
  }
  return context;
};
