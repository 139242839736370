import React, { createContext, useContext, useState } from "react";
import { EventEmitter } from "events";
import axios from "axios";

const OrderContext = createContext();

const OrderEvents = new EventEmitter();

export const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
    total: 0,
    pages: 0,
  });

  const [searchParams, setSearchParams] = useState({
    search: "",
    status: "",
    pickupLocation: "",
    deliveryLocation: "",
    partner: "",
    startDate: null,
    endDate: null,
    dateFilterType: "status",
    remittanceStatus: "",
  });

  const [orderStatistics, setOrderStatistics] = useState(null);

  // Update pagination state
  const updatePagination = (newPagination) => {
    setPagination((prev) => ({ ...prev, ...newPagination }));
  };

  // Update search parameters
  const updateSearchParams = (newParams) => {
    setSearchParams((prev) => ({ ...prev, ...newParams }));
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  // Fetch orders with filters and pagination
  const fetchOrders = async () => {
    try {
      setLoading(true);

      const queryParams = new URLSearchParams({
        ...searchParams,
        startDate: searchParams.startDate?.toISOString().split("T")[0] || "",
        endDate: searchParams.endDate?.toISOString().split("T")[0] || "",
        page: pagination.page,
        limit: pagination.limit,
      }).toString();

      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${baseURL}/orders?${queryParams}`);
      const { data, total, pages } = response.data;

      setOrders(data);
      setPagination((prev) => ({ ...prev, total, pages }));
    } catch (error) {
      console.error("Error fetching orders:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Fetch a specific order by ID
  const fetchOrderById = async (orderId) => {
    try {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${baseURL}/orders/${orderId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching order by ID:", error);
      throw error; // Propagate error to the caller
    }
  };

  // Update an order
  const updateOrder = async (orderId, updatedData) => {
    try {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.put(
        `${baseURL}/orders/${orderId}`,
        updatedData
      );
      const updatedOrder = response.data;

      // Update the order in the context state
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.uuid === updatedOrder.uuid ? updatedOrder : order
        )
      );

      // Émettre un événement pour notifier la mise à jour
      OrderEvents.emit("orderUpdated");

      return updatedOrder; // Return the updated order for chaining
    } catch (error) {
      console.error("Error updating order:", error);
      throw error; // Propagate error to the caller
    }
  };

  // Fetch order statistics
  const fetchOrderStatistics = async () => {
    try {
      const queryParams = new URLSearchParams({
        ...searchParams,
        startDate: searchParams.startDate?.toISOString().split("T")[0] || "",
        endDate: searchParams.endDate?.toISOString().split("T")[0] || "",
      }).toString();

      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(
        `${baseURL}/orders/statistics?${queryParams}`,
        { noLoading: true }
      );
      const { statistics, total } = response.data;

      setOrderStatistics({ statistics, total });
    } catch (error) {
      console.error("Error fetching order statistics:", error);
      throw error; // Propagate error to the caller
    }
  };

  // Context value
  const value = {
    orders,
    loading,
    pagination,
    searchParams,
    orderStatistics,
    fetchOrders,
    fetchOrderById,
    updateOrder,
    updatePagination,
    updateSearchParams,
    fetchOrderStatistics,
    OrderEvents,
  };

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

// Custom hook to use OrderContext
export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error("useOrderContext must be used within an OrderProvider");
  }
  return context;
};
