import React, { lazy, useEffect, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import ContextProvider from "./ContextProvider";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// Lazy loading des pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));

// Initialisation des bibliothèques
initializeApp();
const token = checkAuth();

function App() {
  useEffect(() => {
    themeChange(false); // Initialisation des thèmes DaisyUI
  }, []);

  return (
    <ContextProvider>
      <Router>
        <Suspense
          fallback={<div className="loading-screen">Chargement...</div>}
        >
          <Routes>
            {/* Routes publiques */}
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/documentation" element={<Documentation />} />
            {/* Layout principal */}
            <Route path="/app/*" element={<Layout />} />
            {/* Redirection par défaut */}
            <Route
              path="*"
              element={
                <Navigate to={token ? "/app/orders" : "/login"} replace />
              }
            />
          </Routes>
        </Suspense>

        {/* Notifications globales */}
        <ToastContainer position="top-right" autoClose={5000} />
      </Router>
    </ContextProvider>
  );
}

export default App;
